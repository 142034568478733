import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message, Pagination, Select } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./AdminResellers.css";

const { Option } = Select;

const AdminResellers = () => {
  const navigate = useNavigate();
  const [allUser, setAllUser] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchMobile, setSearchMobile] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(50); // Default number of items per page
  const [balanceFilter, setBalanceFilter] = useState(""); // State for balance filter

  // Pagination logic
  const totalUsers = filteredUsers.length || allUser.length;

  // Pagination change handler
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  // Filter and Search
  const handleFilter = () => {
    let users = [...allUser];

    if (searchQuery.trim() !== "") {
      users = users.filter((user) =>
        user?.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (searchMobile.trim() !== "") {
      users = users.filter((user) => user?.mobile?.includes(searchMobile));
    }
    if (balanceFilter === "high-to-low") {
      users = users.sort((a, b) => b.totalSpent - a.totalSpent);
    } else if (balanceFilter === "low-to-high") {
      users = users.sort((a, b) => a.totalSpent - b.totalSpent);
    }

    setFilteredUsers(users);
    setCurrentPage(1);
  };

  const getAllUser = async () => {
    try {
      const res = await axios.get("/api/admin/get-all-resellers", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllUser(res.data.data);
        setFilteredUsers(res.data.data); // Set initially filtered users to all users
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleDelete(id) {
    const confirm = window.confirm("Are you sure to delete?");
    if (confirm) {
      try {
        const res = await axios.post(
          "/api/admin/delete-user",
          { id: id },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.success) {
          message.success(res.data.message);
          getAllUser();
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    getAllUser();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [searchQuery, balanceFilter, searchMobile]);

  const currentUsers =
    filteredUsers.length > 0
      ? filteredUsers.slice(indexOfFirstUser, indexOfLastUser)
      : allUser.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <AdminLayout>
      <div className="admin-users-container admin-resellers">
        <div className="page-title">
          <h3 className="m-0">Resellers</h3>
          <h6>Total Users - {allUser?.length}</h6>
        </div>
        <hr />
        <div className="tools d-flex align-items-center">
          <input
            type="text"
            placeholder="Search by email"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="p-2"
          />
          <input
            type="text"
            placeholder="Search by mobile"
            value={searchMobile}
            onChange={(e) => setSearchMobile(e.target.value)}
            className="p-2"
          />
          <Select
            defaultValue=""
            style={{ width: 200 }}
            onChange={(value) => setBalanceFilter(value)}
          >
            <Option value="">Sort by Total Spent</Option>
            <Option value="high-to-low">High to Low</Option>
            <Option value="low-to-high">Low to High</Option>
          </Select>
        </div>
        <div className="table-container">
          <table className="table user-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Total Spent</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers?.map((user, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <small>{user?.name}</small>
                    </td>
                    <td>
                      <small>{user?.email}</small>
                    </td>
                    <td>
                      <small>{user?.mobile}</small>
                    </td>
                    <td>
                      <small>{user?.totalSpent}</small>
                    </td>
                    <td>
                      <div className="d-flex gap-2">
                        <EditIcon
                          onClick={() =>
                            navigate(`/admin-edit-user/${user?._id}`)
                          }
                          className="me-2 text-muted"
                        />
                        <DeleteIcon
                          className="text-danger"
                          onClick={() => handleDelete(user?._id)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* Pagination */}
          <div className="pagination-container">
            <Pagination
              current={currentPage}
              total={totalUsers}
              pageSize={usersPerPage}
              onChange={onPageChange}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminResellers;
