import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import "./PhoneSkins.css";
import axios from "axios";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import HowItWorks from "../components/Home/HowItWorks";
import Products from "../components/Products";
import HeroSection from "../components/Home/HeroSection";

const PhoneSkins = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data.reverse());
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getAllProducts();
    }, 2000);
  }, []);

  return (
    <Layout>
      <HeroSection />
      <div className="phone-skin-product-container">
        <Products title={"Mobile Games"} />
      </div>
      <HowItWorks />
    </Layout>
  );
};

export default PhoneSkins;
