import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useSelector } from "react-redux";
import { message, Modal, Button } from "antd";
import axios from "axios";
import AddCardIcon from "@mui/icons-material/AddCard";
import getUserData from "../utils/userDataService.js";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "./Wallet.css";
import { encryptData } from "../utils/encryptData.js";

const Wallet = () => {
  const { user } = useSelector((state) => state.user);
  const [tab, setTab] = useState(0);
  const [btn, setBtn] = useState(0);
  const [form, setForm] = useState({ email: "", amount: "" });
  const [payments, setPayments] = useState(null);
  const [walletAddition, setWalletAdditon] = useState(null); // 0 = pgateway.in || 1 = onegateway
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [history, setHistory] = useState(null);
  const [payBtn, setPayBtn] = useState(null);
  const [balance, setBalance] = useState("");
  const [upi, setUpi] = useState(1); // 0 = pgateway.in || 1 = onegateway

  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  const encryptPayload = (payload) => {
    const ciphertext = encryptData(payload);
    return ciphertext
  }

  useEffect(() => {
    getUserData(null, null, setBalance);
  }, []);

  async function getUserWalletHistory() {
    try {
      const res = await axios.post("/api/wallet/get-wallet-history", {
        email: user?.email,
      });
      if (res.data.success) {
        setHistory(res.data.data.reverse());
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user !== null) {
      getUserWalletHistory();
    }
  }, [user]);

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
  }, []);

  async function payUpiOne() {
    if (form?.amount < 1) {
      return message.error("Enter amount greater than 20");
    }
    if (form?.amount > 2000) {
      return message.error("You cannot add money more than 2000");
    }
    try {
      // setLoading(true);
      setPayBtn(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: form?.amount,
        txn_note: "Wallet Topup Note",
        product_name: "Wallet Topup",
        customer_name: form?.customer_name,
        customer_email: form?.customer_email,
        customer_mobile: form?.customer_mobile,
        callback_url: `https://deeragames.in/api/payment/check-wallet-payment-received?orderId=${orderId}`,
      };

      // const encryptedPaymnetObject = {
      //   data: encryptPayload(paymentObject)
      // }
      const response = await axios.post(
        "/api/payment/add-payment-to-wallet",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
        setTimeout(() => {
          setPayBtn(false);
        }, 1500);
      } else {
        console.log(response.data.message);
        message.error(response.data.message);
        setPayBtn(false);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setLoading(false);
      setPayBtn(false);
    }
  }

  async function payUpiTwo() {
    if (form?.amount < 1) {
      return message.error("Enter amount greater than 20");
    }
    try {
      // setLoading(true);
      setPayBtn(true);
      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: form?.amount,
        paymentNote: user?.sponsor || "noSponsor",
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        redirectUrl: `https://deeragames.in/api/upi/status?id=${user['_id']}`,
      };



      const response = await axios.post("/api/upi/initiate", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
        setLoading(false);
        setTimeout(() => {
          setPayBtn(false);
        }, 1500);
      } else {
        console.log(response.data.message);
        message.error(response.data.message);
        setPayBtn(false);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setLoading(false);
      setPayBtn(false);
    }
  }

  async function getUserPayments() {
    try {
      const res = await axios.post("/api/payment/get-user-payments", {
        email: user?.email,
      });
      if (res.data.success) {
        setPayments(res.data.data.reverse());
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getWalletAdditions() {
    try {
      const res = await axios.post(
        "/api/wallet/get-additions",
        {
          email: user?.email,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setWalletAdditon(res.data.data.reverse());
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setForm({ ...form, [e.target.name]: e.target.value });
    if (name === "amount") {
      if (value < 20) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }

  useEffect(() => {
    if (user?.email) {
      setForm((prev) => ({
        ...prev,
        customer_email: user?.email,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
      }));
      getUserPayments();
      getWalletAdditions();
    }
  }, [user]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Layout>
      <DashboardLayout>
        <div className="wallet-dash">
          <div className="bal w-100">
            Your Balance: DC {parseFloat(balance).toFixed(2)}
          </div>
          <div className="wallet-dash-card" onClick={() => setTab(0)}>
            Transaction History
          </div>
          <div className="wallet-dash-card" onClick={showModal}>
            Add Money
            <AddCardIcon className="icon ms-2" />
          </div>
        </div>

        {tab === 0 && (
          <div className="txn-btns mb-4">
            <button
              onClick={() => setBtn(0)}
              className={`${btn === 0 && "active"}`}
            >
              Payments
            </button>
            <button
              onClick={() => setBtn(1)}
              className={`${btn === 1 && "active"}`}
            >
              Wallet <AddCircleIcon className="icon" />
            </button>
            <button
              onClick={() => setBtn(2)}
              className={`${btn === 2 && "active"}`}
            >
              Wallet <RemoveCircleIcon />
            </button>
          </div>
        )}

        {/* USER HISTORY */}
        {tab === 0 && btn === 0 && (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>OrderId</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {payments &&
                payments.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.client_txn_id}</td>
                      <td>{parseFloat(item.amount).toFixed(2)}</td>
                      <td>
                        {new Date(item?.createdAt).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </td>
                      <td>{item.status}</td>
                      <td>{item.type || "NA"}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}

        {tab === 0 && btn === 1 && (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>OrderId</th>
                <th>Balance Before</th>
                <th>Balance After</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {walletAddition &&
                walletAddition.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.orderId}</td>
                      <td>{parseFloat(item.balanceBefore).toFixed(2)}</td>
                      <td>{parseFloat(item.balanceAfter).toFixed(2)}</td>
                      <td>
                        {new Date(item?.createdAt).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}

        {tab === 0 && btn === 2 && (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Order ID</th>
                <th>Balance Before</th>
                <th>Balance After</th>
                <th>Price</th>
                <th>Product Info</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {history &&
                history.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.orderId}</td>
                      <td>{item.balanceBefore}</td>
                      <td>{item.balanceAfter}</td>
                      <td>{parseFloat(item.price).toFixed(2)}</td>
                      <td>{item.p_info}</td>
                      <td>
                        {new Date(item?.created).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}

        <Modal
          title="Add Money"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          style={{ top: "50%", transform: "translateY(-50%)" }} // Center vertically
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={upi === 0 ? payUpiOne : payUpiTwo}
            >
              {payBtn && (
                <div
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              )}
              Pay Online
            </Button>,
          ]}
        >
          <div className="form-fields mb-2">
            <label htmlFor="">Amount</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter amount"
              name="amount"
              onChange={handleChange}
              value={form?.amount}
            />
            <span className="text-danger">
              {error && "Enter amount greater or equal to 20"}
            </span>
          </div>
        </Modal>
      </DashboardLayout>
    </Layout>
  );
};

export default Wallet;
