import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";

const APIDocsContentLayout = ({ children }) => {
    const location = useLocation();



    return (
        <div className="dashboard-container">
            <div className="dashboard-menu">
                <h4>Getting Started</h4>
                <ul>
                    <li
                        className={`${location.pathname === "/api-docs" && "active"}`}
                    >
                        <Link to="/api-docs">
                            <DashboardIcon className="me-2" />
                            Api-Docs
                        </Link>


                    </li>
                    {/* <div className={`pre-order-items ${location.pathname === "/api-docs" ? 'd-block' : 'd-none'} `}>
                        <li>lorem</li>
                        <li>Ipsum</li>
                    </div>
                    <li className={`${location.pathname === "/wallet" && "active"}`}>
                        <Link to="/">
                            <WalletIcon className="me-2" />
                            Post
                        </Link>

                    </li>
                    <div className={`pre-order-items ${location.pathname === "/wallet" ? 'd-block' : 'd-none'} `}>
                        <li>lorem</li>
                        <li>Ipsum</li>
                    </div> */}

                </ul>
            </div>
            <div className="dashboard-content">{children}</div>
        </div>
    );
};

export default APIDocsContentLayout;
