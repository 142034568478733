import React, { useState } from "react";
import { Button, Flex, message, Table } from "antd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Link } from "react-router-dom";
import axios from "axios";

const OrderTable = ({ user, loading, getAllOrders, status }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const columns = [
    {
      title: "Order ID",
      dataIndex: "client_txn_id",
    },
    {
      title: "Order Type",
      dataIndex: "",
      key: user?.client_txn_id,
      render: (user) => (user?.api === "no" ? "Manual Order" : "Api Orders"),
    },
    {
      title: "Email",
      dataIndex: "customer_email",
    },
    {
      title: "Total",
      dataIndex: "price",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Timestamp",
      dataIndex: "",
      key: user?.client_txn_id,
      render: (user) => {
        return new Date(user?.createdAt)?.toLocaleString("default", {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        });
      },
    },
    {
      title: "View",
      dataIndex: "",
      key: user?.client_txn_id,

      render: (user) => (
        <Link to={`/admin-view-order/${user?._id}`}>
          <RemoveRedEyeIcon />
        </Link>
      ),
    },
  ];

  const dataSource = user?.map((item, i) => ({
    ...item,
    key: i,
  }));
  const start = () => {
    getAllOrders();
  };
  const rowSelection = {
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const clickAll = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  const sendRefund = () => {
    if (selectedRows.length === 0) return;

    //allow only orders with status 'failed' to be refunded
    let statusFlag = false;
    const payload = selectedRows?.map((row) => {
      if (row.status !== "failed") {
        statusFlag = true;
        return null;
      }
      return {
        dbId: row._id,
        client_txn_id: row?.client_txn_id,
        customer_email: row.customer_email,
        refundAmount: +row.price,
      };
    });

    if (statusFlag) {
      message.error("Only select failed orders!");
      return;
    }

    const res = window.confirm(
      `Are you sure to refund ${selectedRows.length} users?`
    );

    if (!res) return;

    let data = JSON.stringify(payload);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/admin/refund-users",
      headers: {
        "Content-Type": "application/json",

        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.success) {
          message.success(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.data?.message);
      })
      .finally(() => {
        clickAll();
      });
  };

  const hasSelected = selectedRows.length > 0;
  return (
    <Flex gap="middle" vertical>
      <Flex align="center" gap="middle">
        <Button type="primary" onClick={start} loading={loading}>
          Reload
        </Button>
        <Button
          type="primary"
          onClick={sendRefund}
          disabled={selectedRows?.length === 0}
          loading={loading}>
          Refund
        </Button>
        {hasSelected ? (
          <span className="text-dark">
            Selected {selectedRows.length} items
          </span>
        ) : null}
      </Flex>
      <Table
        rowSelection={{
          ...rowSelection,
          selectedRowKeys: selectedRowKeys,
        }}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
      />
    </Flex>
  );
};
export default OrderTable;
