import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import "./AdminBulkEmail.css";
import axios from "axios";
import { message } from "antd";

const AdminBulkEmail = () => {
  const [incompleteUsers, setIncompleteUsers] = useState(null);
  // const [msg, setMsg] = useState("");

  // const handleIncompleteProfileMail = async (e) => {
  //   e.preventDefault();
  //   try {
  //     setLoading(true);
  //     const res = await axios.post(
  //       "/api/admin/send-mail-to-incomplete-profiles",
  //       { incompleteUsers, msg }
  //     );
  //     if (res.data.success) {
  //       message.success(res.data.message);
  //       setMsg("");
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //       message.error(res.data.message);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(error);
  //   }
  // };

  const handleSendMailToAll = async (e) => {
    e.preventDefault();
  };

  const getIncompleteUsers = async () => {
    try {
      const res = await axios.get("/api/admin/get-incomplete-users");
      if (res.data.success) {
        setIncompleteUsers(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getIncompleteUsers();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Bulk Email</h3>
        </div>
      </div>
      <div className="bulk-email-container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <form
              onSubmit={handleSendMailToAll}
              className="form bg-white p-3 mt-3"
            >
              <textarea
                className="form-control"
                name="msg"
                cols="30"
                rows="5"
              ></textarea>
              <button type="submit" className="register-btn mt-2">
                Send Mail
              </button>
            </form>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminBulkEmail;
