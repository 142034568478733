import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RegisterDeveloperForm from "./RegisterDeveloperForm";
const MyModal = () => {
  const [modal2Open, setModal2Open] = useState(false);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const handleClick = () => {
    if (!user) navigate("/login");
    setModal2Open(true);
  };
  return (
    <>
      <Button type="link" onClick={handleClick}>
        Click Here
      </Button>
      <Modal
        title="Register Your Domain Here"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        footer="">
        <RegisterDeveloperForm />
      </Modal>
    </>
  );
};
export default MyModal;
