import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import axios from "axios";
import "./AdminAddBrand.css";
import DeleteIcon from "@mui/icons-material/Delete";

const AdminAddBrand = () => {
  // states to fetch data
  const [brands, setBrands] = useState(null);
  // State for submitting
  const [form, setForm] = useState({});
  const [model, setModel] = useState({
    name: "",
    model: "",
  });

  const modelList =
    brands &&
    brands.filter((item) => {
      return item.name === model.name;
    });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/admin/add-brand", form, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        getAllBrands();
        setForm({ name: "" });
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleModelChange = (e) => {
    setModel({ ...model, [e.target.name]: e.target.value });
  };

  const handleSubmitModel = async (e) => {
    e.preventDefault();
    if (model?.name === "") {
      return;
    }
    try {
      const res = await axios.post("/api/admin/add-model", model, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        getAllBrands();
        setModel({ model: "", name: model?.name });
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // DELETE
  const handleDeleteModel = async (mod) => {
    try {
      const res = await axios.post(
        "/api/admin/delete-model",
        {
          name: model?.name,
          modelName: mod,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        getAllBrands();
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // DELETE

  const getAllBrands = async () => {
    try {
      const res = await axios.get("/api/admin/get-all-brands", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setBrands(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllBrands();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Add Brands</h3>
        </div>
        <div className="add-brand-container">
          <div className="add-brand">
            <input
              onChange={handleChange}
              value={form.name}
              placeholder="Enter brand name"
              type="text"
              name="name"
            />
            <button onClick={handleSubmit}>Add</button>
            <div className="brands">
              <span>Brand List</span>
              {brands &&
                brands?.map((brand, index) => {
                  return (
                    <div key={index} className="brand">
                      <span>{brand.name}</span>
                      <DeleteIcon className="icon" />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="add-model">
            <select onChange={handleModelChange} name="name">
              <option value="">Select</option>
              {brands &&
                brands?.map((brand, index) => {
                  return (
                    <option key={index} value={brand.name}>
                      {brand.name}
                    </option>
                  );
                })}
            </select>
            <input
              onChange={handleModelChange}
              value={model?.model}
              name="model"
              placeholder="Enter model name"
              type="text"
            />
            <button onClick={handleSubmitModel}>Add Model</button>
            <div className="brands">
              <span>{model?.name} Model List</span>
              {brands &&
                modelList[0]?.models?.map((model, index) => {
                  return (
                    <div key={index} className="brand">
                      <span>{model}</span>
                      <DeleteIcon
                        onClick={() => handleDeleteModel(model)}
                        className="icon"
                      />
                    </div>
                  );
                })}
            </div>
            <div className="my-5 text-center">
              {modelList && modelList[0]?.models?.length === 0
                ? "No Models Added"
                : ""}
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminAddBrand;
