import React from 'react'
import APIDocsLayout from '../components/Layout/APIDocsLayout'
import APIDocsContentLayout from '../components/Layout/APIDocsContentLayout'
import "./APIDocs.css"
import MyModal from '../components/APIDocs/MyModal'

const APIDocs = () => {
    return (
        <APIDocsLayout>
            <APIDocsContentLayout>
                <div className="api-docs">
                    <h1>Documentation</h1>
                    <h3>deeragames.in integration
                    </h3>
                    <p>
                        deeragames.in API documentation <br />
                        The deeragames.in API is a service used to integrate features on deeragames.in with partners.
                    </p>

                    <h5 className='mt-2'>

                        Production URL:    <code>
                            https://deeragames.in/DeeraAPI/v1/
                        </code>
                    </h5>

                    <section id="precondition" className='mt-4' >
                        <h2><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>Pre-Condition</font></font></h2>
                        <p class="lead"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>To begin, there is 1 integration method, which is via API with the POST method. You need API Key and Domain name registration for the Authentication process to deeragames.in system.</font></font></p>
                        <ul>
                            <li><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>Register Account: &nbsp;</font></font><a href="https://deeragames.in/register" rel="noreferrer noopener nofollow" style={{ color: 'rgb(239, 91, 37) !important' }}><font style={{ verticalAlign: 'inherit' }}><code style={{ verticalAlign: 'inherit', textDecoration: 'underline' }}>https://deeragames.in/register</code></font></a></li>
                            <li><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>               Register Domain                {<MyModal />}
                            </font></font>
                            </li>


                        </ul>
                    </section>
                    <section className='mt-4'>
                        <h2>View Documentation</h2>
                        <a href="https://documenter.getpostman.com/view/37041396/2sAYHwJjcN" target='_blank' rel="noreferrer">Click Here</a>
                    </section>
                </div>

            </APIDocsContentLayout>
        </APIDocsLayout >
    )
}

export default APIDocs