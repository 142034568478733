import React from "react";
import { Button, Form, message, Select } from "antd";
import axios from "axios";

const URLREGEX =
  /^((?!-))(xn--)?^(http:\/\/|https:\/\/)[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;

const registerDeveloper = (urlArr) => {
  let data = JSON.stringify({
    host: Array.from(urlArr),
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://deeragames.in/DeeraAPI/v1/register-developer",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if (response.status === 200) message.success(response?.data?.message);
    })
    .catch((error) => {
      message.error(error?.response?.data?.error);
      console.error(error);
    });
};
const onFinish = (values) => {
  const { domain: urlArr } = values;
  console.log({ urlArr });
  registerDeveloper(urlArr);
};
const onFinishFailed = () => {
  message.error("Enter valid URL as per format");
  return;
};

const RegisterDeveloperForm = () => {
  return (
    <Form
      name="basic"
      layout="vertical"
      labelCol={{
        span: 10,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <Form.Item
        label="Enter Domain Name(s)"
        name="domain"
        rules={[
          {
            required: true,
            message: "Please input atleast one URL",
          },
          {
            validator: (_, values) =>
              values.some((item) => !item.match(URLREGEX))
                ? Promise.reject(
                    new Error("Enter a valid URL\ne.g. http(s)://google.com")
                  )
                : Promise.resolve(),
          },
        ]}>
        <Select
          name="domain"
          mode="tags"
          style={{
            width: "100%",
          }}
          placeholder="https://google.com"
        />
      </Form.Item>

      <Form.Item label={null}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
export default RegisterDeveloperForm;
