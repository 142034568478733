import React, { useEffect } from "react";
import "./Layout.css";
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";
import APIDocsHeader from "../Header/APIDocsHeader";

const APIDocsLayout = ({ children }) => {
    const { pathname } = useLocation();

    const ScrollToTop = () => {
        const { pathname } = useLocation();
        useEffect(() => {
            window.scrollTo(0, 0);
            // eslint-disable-next-line
        }, [pathname]);
        return null;
    };
    ScrollToTop();
    return (
        <React.Fragment>
            <APIDocsHeader />
            <div className="body" style={{ minHeight: '61vh' }}>{children}</div>
            <Footer />
        </React.Fragment>
    );
};

export default APIDocsLayout;
